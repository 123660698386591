/* Reset and Base Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: #fff;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Netflix Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: #000;
    color: #fff;
    line-height: 1.4;
    font-weight: 400;
}

/* Header Styles */
.header {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 20px 4%;
    z-index: 999;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
    transition: background-color 0.3s ease;
}

.header.scrolled {
    background-color: #141414;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.logo {
    display: flex;
    align-items: center;
}

.logo span {
    color: #E50914;
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.header.scrolled .logo span {
    color: #E50914;
}

.nav-links {
    display: flex;
    gap: 20px;
    align-items: center;
}

/* Button Styles */
.btn-primary {
    background-color: #E50914;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    gap: 8px;
}

.btn-primary:hover {
    background-color: #f40612;
}

/* Hero Section */
.hero {
    height: 80vh;
    background: url('../public/images/featureimageTYA.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    padding: 0 20px;
}

.hero-content {
    max-width: 800px;
    margin: 250px auto 30px auto;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 20px 40px;
    border-radius: 8px;
    display: inline-block;
}

.hero h1 {
    font-size: 3.5rem;
    margin-bottom: 0;
    line-height: 1.2;
    font-weight: 700;
}

.hero h2 {
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 30px;
}

.hero p {
    font-size: 1.25rem;
    margin-bottom: 20px;
    font-weight: 400;
}

.hero .email-signup {
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
}

.hero .email-signup input {
    flex: 1;
    padding: 15px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    min-width: 0;
}

/* Email Signup */
.email-signup {
    display: flex;
    gap: 10px;
    max-width: 600px;
    margin: 0 auto;
}

.email-signup input {
    flex: 1;
    padding: 15px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    color: #000;
    background-color: #fff;
}

.email-signup input::placeholder {
    color: #666;
}

/* Email Section */
.email-section {
    padding: 70px 45px;
    border-top: 8px solid #222;
    background-color: #000;
}

.email-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 50px;
}

.email-content {
    flex: 1;
    text-align: left;
}

.email-content p {
    font-size: 1.25rem;
    margin-bottom: 20px;
    font-weight: 400;
}

.email-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.email-image img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

/* FAQ Section */
.faq {
    padding: 70px 45px;
    border-top: 8px solid #222;
    background-color: #000;
}

.faq-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    gap: 50px;
    align-items: flex-start;
}

.faq-content {
    flex: 1;
    text-align: left;
}

.faq h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
    text-align: left;
    font-weight: 700;
}

.faq-list {
    max-width: 800px;
    margin: 0;
}

.faq-item {
    margin-bottom: 40px;
}

.faq-item:last-child {
    margin-bottom: 0;
}

.faq-question {
    width: 100%;
    padding: 15px 20px;
    background-color: #303030;
    border: none;
    color: #fff;
    font-size: 1.25rem;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 400;
}

.faq-question i {
    color: #fff;
}

.faq-answer {
    background-color: #303030;
    padding: 15px 20px;
    text-align: left;
    display: none;
    margin-top: 8px;
}

.faq-item.active .faq-answer {
    display: block;
}

.faq-item.active .fa-plus {
    transform: rotate(45deg);
}

.faq-answer p {
    margin-bottom: 20px;
    text-align: left;
}

.faq-answer p:last-child {
    margin-bottom: 0;
}

.faq-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 100px;
    max-height: 500px;
}

.faq-image img {
    max-width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
}

/* Footer */
.footer {
    padding: 40px 45px 0 45px;
    background-color: #1a1a1a;
    color: #fff;
}

.footer-content {
    max-width: 1000px;
    margin: 0 auto;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.footer-company {
    text-align: center;
}

.footer-company span {
    font-weight: 500;
    color: #fff;
    font-size: 16px;
}

.footer-links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0;
    flex-wrap: wrap;
}

.footer-links a {
    color: #fff;
    text-decoration: underline;
    font-size: 14px;
    padding: 0 10px;
    border-right: 1px solid #333;
    font-weight: 400;
}

.footer-links a:last-child {
    border-right: none;
}

.footer-links a:hover {
    text-decoration: none;
    color: #ccc;
}

/* Tablet Styles (768px - 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
    .hero .email-signup {
        max-width: 600px;
        margin-top: 20px;
        flex-direction: row;
        gap: 10px;
    }

    .hero .email-signup input {
        width: auto;
        flex: 1;
    }

    .hero .email-signup .btn-primary {
        width: auto;
    }

    .faq-container {
        flex-direction: column;
        align-items: center;
        gap: 100px;
    }

    .faq-content {
        text-align: center;
        max-width: 800px;
        margin: 0 auto;
    }

    .faq-image {
        position: static;
        margin-top: 0;
    }

    .faq-list {
        min-height: auto;
    }

    .faq h2 {
        text-align: center;
    }

    .faq-question {
        text-align: center;
    }

    .faq-answer {
        text-align: center;
    }

    .email-content {
        text-align: left;
    }

    .email-content .email-signup {
        text-align: left;
        flex-direction: column;
        gap: 15px;
    }

    .email-content .email-signup input {
        width: 100%;
    }

    .email-content .email-signup .btn-primary {
        width: 100%;
        justify-content: center;
    }

    .email-container {
        flex-direction: row;
    }

    .email-image {
        order: 0;
        margin: 0;
    }

    .email-image img {
        max-width: 100%;
        margin: 0;
    }

    .email-content {
        order: 0;
    }
}

/* Mobile Styles (up to 767px) */
@media (max-width: 767px) {
    .footer {
        padding: 40px 25px 0 25px;
    }

    .footer-content {
        gap: 15px;
    }

    .footer-links {
        flex-wrap: nowrap;
    }

    .footer-links a {
        display: inline-block;
        border-right: 1px solid #333;
        padding: 0 10px;
    }

    .footer-links a:last-child {
        border-right: none;
    }

    .hero h1 {
        font-size: 2rem;
    }

    .hero .email-signup {
        max-width: 400px;
        flex-direction: column;
        gap: 15px;
    }

    .hero .email-signup input {
        width: 100%;
    }

    .hero .email-signup .btn-primary {
        width: 100%;
        justify-content: center;
    }

    .faq-container {
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }

    .faq-content {
        text-align: center;
        max-width: 100%;
    }

    .faq-image {
        position: static;
        margin-top: 0;
        max-height: none;
    }

    .faq-image img {
        max-width: 300px;
        margin: 0 auto;
    }

    .faq h2 {
        text-align: center;
        font-size: 2rem;
    }

    .faq-answer {
        text-align: center;
    }

    .email-container {
        flex-direction: column;
        gap: 30px;
    }

    .email-content {
        text-align: center;
    }

    .email-content .email-signup {
        flex-direction: column;
        gap: 15px;
    }

    .email-content .email-signup input {
        width: 100%;
    }

    .email-content .email-signup .btn-primary {
        width: 100%;
        justify-content: center;
    }

    .email-image {
        order: -1;
    }

    .email-image img {
        max-width: 300px;
        margin: 0 auto;
    }
}

@media (max-width: 480px) {
    .hero h1 {
        font-size: 2rem;
    }

    .footer-links {
        grid-template-columns: 1fr;
    }

    .hero .email-signup {
        max-width: 400px;
    }
}

/* Header Responsive Styles */
@media (min-width: 300px) and (max-width: 389px) {
    .hero-content {
        margin: 275px auto 30px auto;
    }
}

@media (min-width: 390px) and (max-width: 413px) {
    .hero-content {
        margin: 325px auto 30px auto;
    }
}

@media (min-width: 414px) and (max-width: 539px) {
    .hero-content {
        margin: 350px auto 30px auto;
    }
}

@media (min-width: 768px) and (max-width: 819px) {
    .hero-content {
        margin: 450px auto 30px auto;
    }
}

@media (min-width: 820px) and (max-width: 1200px) {
    .hero-content {
        margin: 500px auto 30px auto;
    }
} 